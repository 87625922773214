import * as React from "react"
import { graphql } from "gatsby"
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';

mapboxgl.workerClass = MapboxWorker;

import { TsmlUI } from "tsml-ui-react"
import "tsml-ui-react/lib/styles.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const mapbox_token = "pk.eyJ1IjoiY2lnemlnd29uIiwiYSI6ImNrZmg5eG5jNDAwNXoyeHM0MTl4M3ppNmIifQ.I8y80GTABIN495k-su0cMA"

const MeetingsPage = ({ data }) => {
  const feed_url = data.site.siteMetadata.origin + '/meetings.json'

  return (
    <Layout>
      <Seo title="Meetings" slug="meetings" />
      <header className="bg-azure">
        <div className="container mx-auto px-4 pt-16 pb-8">
          <h1 className="text-4xl">District 28 AA Meetings are held in the following towns and villages:</h1>
          <ul className="block md:flex py-8">
            <li className="w-full md:w-1/3 lg:w-1/5 py-2">
              <strong>ALPENA COUNTY:</strong><br />
              Alpena, Ossineke, Hillman
            </li>
            <li className="w-full md:w-1/3 lg:w-1/5 py-2">
              <strong>MONTMORENCY COUNTY:</strong><br />
              Atlanta, Lewiston
            </li>
            <li className="w-full md:w-1/3 lg:w-1/5 pt-2">
              <strong>PRESQUE ISLE COUNTY:</strong><br />
              Posen, Rogers City, Millersburg
            </li>
          </ul>
          <a className="underline text-blue-600" href="https://www.aa.org/pages/en_US/meeting-guide" target="_blank" rel="noreferrer">
            Get AA Meeting Guide App for Android/iPhone
          </a>
        </div>
      </header>
      <main>
        <div className="container mx-auto px-4 pt-8 pb-16">
          <TsmlUI 
            json={ feed_url }
            timezone="America/Detroit" 
            mapbox={ mapbox_token }
          />
        </div>
      </main>
    </Layout>
  )
}

export default MeetingsPage

export const pageQuery = graphql`
  query MeetingsPageQuery {
    site {
      siteMetadata {
        origin
      }
    }
  }
`
